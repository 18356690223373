exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-parcours-tsx": () => import("./../../../src/pages/parcours.tsx" /* webpackChunkName: "component---src-pages-parcours-tsx" */),
  "component---src-templates-action-tsx": () => import("./../../../src/templates/action.tsx" /* webpackChunkName: "component---src-templates-action-tsx" */),
  "component---src-templates-journey-tsx": () => import("./../../../src/templates/journey.tsx" /* webpackChunkName: "component---src-templates-journey-tsx" */)
}

