import { API_URL, isLoginEnable } from './constants';

type Role = 'ADMIN' | 'BETA_TESTER';
type User = {
  userName: string;
  isContributor: boolean;
  initiativesLed: string[];
  roles: Role[];
};

export const userQuery = {
  queryKey: ['me'],
  queryFn: (): Promise<User> =>
    isLoginEnable
      ? fetch(`${API_URL}/a/me`, { credentials: 'include' }).then((res) => {
          return res.json();
        })
      : Promise.resolve({}),
};

export type APIError = {
  type: string;
  message: string;
  title: string;
};
