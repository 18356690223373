import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';
import { APIError, userQuery } from '../../queries';

type Role = 'ADMIN' | 'BETA_TESTER';

type User = {
  userName: string;
  isContributor: boolean;
  initiativesLed: string[];
  roles: Role[];
};

type UserStatus = 'success' | 'loading' | 'error';

type UserContextSuccess = {
  type: 'success';
  user: User;
};

type UserContextLoading = {
  type: 'loading';
  isLoading: boolean;
};

type AppError = {
  type: string;
  title: string;
  message: string;
  cause: string | unknown | undefined;
  stack: string | undefined;
};
// FIXME Improve typing for error
type UserContextError = {
  type: 'error';
  error: AppError | APIError;
};

type UserContextType =
  | UserContextSuccess
  | UserContextLoading
  | UserContextError;

const UserContext = createContext<null | UserContextType>(null);

export const useUser = () => {
  const userCxt = useContext(UserContext);

  if (!userCxt) {
    throw new Error(
      'The useUserContext hook can only be used within UserProvider.',
    );
  }

  if (userCxt.type === 'loading') {
    return {
      user: {
        userName: '',
        isContributor: false,
        initiativesLed: [],
        roles: [],
      },
      isLoading: true,
      isContributor: false,
      isAdmin: false,
      isLoggedIn: false,
      userError: null,
    };
  }

  if (userCxt.type === 'error') {
    return {
      user: {
        userName: '',
        isContributor: false,
        initiativesLed: [],
        roles: [],
      },
      isLoading: false,
      isContributor: false,
      isAdmin: false,
      isLoggedIn: false,
      userError: userCxt.error,
    };
  }

  const isAdmin = userCxt.user.roles?.includes('ADMIN');
  const isContributor = userCxt.user.isContributor;
  return {
    user: userCxt.user,
    isContributor: isContributor,
    isAdmin: isAdmin,
    isLoggedIn: true,
    isLoading: false,
    userError: null,
  };
};

/**
 * This hooks manage a big part of the business logic
 */

type UserAccessRights = {
  canFollowThematique: boolean;
  canFollowInitiative: boolean;
  canContactPilot: boolean;
  canManageInitiative: boolean;
  canCreateIAInitiative: boolean;
  canCreateCAInitiative: boolean;
  canAccessStaffing: boolean;
  canAccessDeliverables: boolean;
};
export const useUserAccessRights = (): UserAccessRights => {
  const { user, isAdmin, isContributor, isLoggedIn } = useUser();
  if (!isLoggedIn) {
    return {
      canFollowThematique: false,
      canFollowInitiative: false,
      canContactPilot: false,
      canManageInitiative: false,
      canCreateIAInitiative: false,
      canCreateCAInitiative: false,
      canAccessStaffing: false,
      canAccessDeliverables: false,
    };
  }

  if (isAdmin) {
    return {
      canFollowThematique: true,
      canFollowInitiative: true,
      canContactPilot: true,
      canManageInitiative: true,
      canCreateIAInitiative: true,
      canCreateCAInitiative: true,
      canAccessStaffing: true,
      canAccessDeliverables: true,
    };
  }

  const isPilot = (user && user?.initiativesLed.length > 0) ?? false;
  const isUserAutorisedToManageInitiative = false;

  const loggedInUserAccessRights = {
    canFollowThematique: isContributor,
    canFollowInitiative: isContributor,
    canContactPilot: isContributor,
    canManageInitiative: isUserAutorisedToManageInitiative,
    canCreateIAInitiative: isContributor,
    canCreateCAInitiative: isPilot,
    canAccessStaffing: isPilot,
    canAccessDeliverables: isPilot,
  };

  return loggedInUserAccessRights;
};

export const UserProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const queryData = useQuery({
    ...userQuery,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  let contextValue: UserContextType = { type: 'loading', isLoading: true };
  if (queryData.isError) {
    const err = queryData.error as any;
    if (err.json?.error) {
      const jsonErr = err.json;
      contextValue = {
        type: 'error',
        error: {
          type: jsonErr.type,
          title: jsonErr.title,
          message: jsonErr.message,
          cause: jsonErr.cause,
          stack: jsonErr.stack,
        },
      };
    } else {
      contextValue = {
        type: 'error',
        error: queryData.error as AppError | APIError,
      };
    }
  }

  if (queryData.isSuccess) {
    contextValue = { type: 'success', user: queryData.data as User };
  }

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
